import * as React from "react";
import MainLayout from "../../components/MainLayout/MainLayout";
import Home from "../../components/Home/Home";

const IndexPage = () => (
	<MainLayout lang="en" title="home">
		<Home />
	</MainLayout>
);

export default IndexPage;
